import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
function BrandNavigation(props) {
    let navigate = useNavigate();
    const expandCollapse = e => {
        e.preventDefault();
        if (IsFullScreenCurrently()) {
            GoOutFullscreen();
        } else {
            openFullscreen();
        }
    };
    const IsFullScreenCurrently = () => {
        //var full_screen_element = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;

        if (
            (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
        ) {
            localStorage.setItem("fullscreen", "full");
            return false;
        } else {
            localStorage.setItem("fullscreen", "exit");
            return true;
        }
    };
    const GoOutFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-compress");
        document.getElementById("expand-button").classList.add("fa-expand");
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
    };
    //let elem = props.data;
    let elem = document.body;
    const openFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-expand");
        document.getElementById("expand-button").classList.add("fa-compress");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    };
    const logOut = e => {
        e.preventDefault();
        /**/
        Swal.fire({
            text: "Are you sure want to logout?",
            icon: "success",
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#205380",
            denyButtonColor: "#205380",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            background: "#1d2530",
            color: "#fff",
            iconColor: "#0B0",
            width: "500px",
            padding: "10px"
        }).then(result => {
            if (result.isConfirmed) {
                localStorage.clear();
                navigate("/");
                props.masterLayoutFun("hide");
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    function exitHandler() {
        if (
            !document.fullscreenElement &&
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            // console.log("ESC");
            document.getElementById("expand-button").classList.add("fa-expand");
            document.getElementById("expand-button").classList.remove("fa-compress");
        }
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-fixed-top">
                <div className="previosnext">
                    <ul>
                        <li>
                            <Link to="#" onClick={e => e.preventDefault()}>
                                <i className="fa-thin fa-chevron-left" aria-hidden="true"></i>
                            </Link>
                        </li>{" "}
                        &nbsp;
                        <li>
                            <Link to="#" onClick={e => e.preventDefault()}>
                                <i className="fa-thin fa-chevron-right" aria-hidden="true"></i>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="centerbtn">
                    <Link to="#" className="btn btn-success" onClick={e => e.preventDefault()}>
                        New Brand
                    </Link>
                    <Link to="#" className="btn btn-success" onClick={e => e.preventDefault()}>
                        New DSP
                    </Link>
                    <Link to="#" className="btn btn-success" onClick={e => e.preventDefault()}>
                        New IMS
                    </Link>
                </div>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown profileopen">
                        <Link
                            className="nav-link"
                            data-toggle="dropdown"
                            to="#"
                            onClick={e => e.preventDefault()}
                            aria-expanded="true">
                            <div className="profiletext">
                                <span className="username">
                                    {localStorage.getItem("name")}{" "}
                                    <i className="fa-thin fa-chevron-down" aria-hidden="true"></i>
                                </span>
                                <span className="userdesignation include-email">{localStorage.getItem("email")}</span>
                                <span className="userdesignation">User</span>
                            </div>
                        </Link>
                        <div
                            className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                            style={{ left: "inherit", right: "0px" }}></div>
                    </li>
                    <li className="nav-item dropdown notificatonbell">
                        <Link
                            className="nav-link"
                            data-toggle="dropdown"
                            to="#"
                            onClick={e => e.preventDefault()}
                            aria-expanded="true">
                            <i className="fa-thin fa-bell button-not-active"></i>
                            {/* <span className="badge badge-warning navbar-badge">1</span> */}
                        </Link>
                        {/* <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ "left": "inherit", "right": "0px" }}>
              <span className="dropdown-item dropdown-header">15 Notifications</span>
              <div className="dropdown-divider"></div>
              <Link to="#" className="dropdown-item">
                4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link to="#" className="dropdown-item">
                8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link to="#" className="dropdown-item dropdown-footer">See All Notifications</Link>
            </div> */}
                    </li>
                    <li className="nav-item" id="">
                        <Link
                            className="nav-link btn btn-success button-not-active "
                            data-widget="search"
                            to="#"
                            onClick={e => e.preventDefault()}
                            role="button">
                            <i className="fa-thin fa-magnifying-glass"></i>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link id="" className="nav-link btn btn-success" to="#" onClick={expandCollapse} role="button">
                            <i className="fa-thin fa-expand" id="expand-button"></i>
                        </Link>
                    </li>
                    <li className="nav-item logoutbtn">
                        <Link
                            className="nav-link btn btn-success"
                            data-widget="logout"
                            to="#"
                            onClick={logOut}
                            role="button">
                            <i className="fa-thin fa-power-off" aria-hidden="true"></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}
export default BrandNavigation;
