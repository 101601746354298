import { memo, useState, useRef } from "react";
import { useDrop } from "react-dnd";

const style = {
    height: "12rem",
    width: "12rem",
    marginRight: "1.5rem",
    marginBottom: "1.5rem",
    color: "white",
    padding: "1rem",
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "normal",
    float: "left"
};
const newStyle = {};

export const Dustbin = memo(function Dustbin({ accept, lastDroppedItem, onDrop, label, clear, arrData, valids }) {
    //console.log("onDrop",onDrop);
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });
    const [ent, setEnt] = useState(true);
    const disallow = useRef();
    const isActive = isOver && canDrop;
    let backgroundColor = "#222";

    if (lastDroppedItem && ent) {
        arrData(label, lastDroppedItem.name);
    }

    const clearIt = ele => {
        setEnt(false);
    };

    if (valids == false) {
        newStyle["borderColor"] = " ";
        newStyle["borderWidth"] = " ";
    }
    if (valids == true) {
        newStyle["borderColor"] = "red";
        newStyle["borderWidth"] = "1px";
    }

    if (isActive) {
        newStyle["borderColor"] = " ";
        newStyle["borderWidth"] = " ";
    } else if (canDrop) {
        newStyle["borderColor"] = "#00AEEF";
        newStyle["borderWidth"] = "1px";
    }

    return (
        <div ref={lastDroppedItem && ent ? disallow : drop} className="products-col">
            <div className="form-group">
                {lastDroppedItem && ent ? (
                    <>
                        <div className="perfom-input active">
                            {lastDroppedItem.name}
                            <span className="removethis-entry">
                                <i
                                    className="fa fa-times pts"
                                    aria-hidden="true"
                                    onClick={() => {
                                        clear(lastDroppedItem.name, label);
                                        clearIt(lastDroppedItem.name);
                                    }}></i>
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={"perfom-input blank"}
                            onDrop={() => {
                                setEnt(true);
                            }}
                            style={{ ...newStyle, newStyle }}>
                            Drop <span className="draggerdfield">{label}</span> field here
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});
