import React, { useState } from "react";
import { Link } from "react-router-dom";
import TermsConditions from "./../static/termsCondition";
import PrivacyPolicy from "./../static/privacyPolicy";
import packageJson from "./../../../package.json";
import moment from "moment";

function BrandFooter(props) {
    /* #region  STATES */
    const [termsCondition, setTermsCondtion] = useState("none");
    const [privacyPolicy, setPrivacyPolicy] = useState("none");
    /* #endregion */

    /* #region  NETHODS */
    const setShowTermsCondition = e => {
        e.preventDefault();
        setTermsCondtion("none");
    };

    const isVisibleTermsCondition = e => {
        e.preventDefault();
        //setTermsCondtion('block')
    };

    const setShowPrivacyPolicy = e => {
        e.preventDefault();
        setPrivacyPolicy("none");
    };

    const isVisiblePrivacyPolicy = e => {
        e.preventDefault();
        // setPrivacyPolicy('block')
    };
    /* #endregion */

    return (
        <>
            <div className="footer">
                <TermsConditions setShowTermsCondition={setShowTermsCondition} isVisible={termsCondition} />
                <PrivacyPolicy setShowPrivacyPolicy={setShowPrivacyPolicy} isVisible={privacyPolicy} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7 nopadding-left">
                            <div className="leftftrmenu">
                                <div className="menuftlogo">
                                    <img alt="logo" src={props.logo} />
                                </div>
                                <div className="control-switch">
                                    <div className="controlftrbtn compact-views">
                                        <span className="control-item">Compact Menu</span>
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="controlftrbtn dark-modeon">
                                        <span className="control-item">Dark Mode</span>
                                        <label className="switch">
                                            <input type="checkbox" defaultChecked />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="controlftrbtn tooltipson">
                                        <span className="control-item">Tooltips</span>
                                        <label className="switch">
                                            <input type="checkbox" defaultChecked />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="leftftrmenu rightmenuftr">
                                <div className="ftrlinks">
                                    <ul>
                                        <li>
                                            <Link to="#" onClick={isVisibleTermsCondition}>
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={isVisiblePrivacyPolicy}>
                                                Privacy & Security
                                            </Link>
                                        </li>
                                    </ul>

                                    <div className="versiontext">
                                        <p>BOtag Norge AS © {moment().format("yyyy")}</p>
                                    </div>

                                    <div className="versiontext">
                                        <p>{`V ${packageJson.version}`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BrandFooter;
