import React, { useEffect, useRef, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLoader from "../loader/preLoader.js";
import "./../../assets/css/checkbox.css";
import * as Library from "./../../utils/Library";
toast.configure();
createTheme(
    "solarized",
    {
        text: {
            primary: "#fff",
            secondary: "#fff"
        },
        background: {
            default: "#10141B"
        },
        context: {
            background: "#cb4b16",
            text: "#FFFFFF"
        },
        divider: {
            default: "#333F50"
        },
        action: {
            button: "rgba(0,0,0,.54)",
            hover: "rgba(0,0,0,.08)",
            disabled: "rgba(0,0,0,.12)"
        }
    },
    "dark"
);

function ImsList(props) {
    document.title = "BOstageA - Invite Brand List";
    const ref = useRef();

    /* #region  STATES */
    const [elementData, setElementData] = useState("");
    const [disabledbtn, setDisabledbtn] = useState(true);
    const [disabledbt, setDisabledbt] = useState(false);
    const [tooltipImg, setTooltip] = useState(true);
    const [tooltipText, setTooltipText] = useState(true);
    const [inviteList, setInviteList] = useState([]);
    const [dataLoading, setDataLoading] = useState();
    const [gtStatus, setGtStatus] = useState([]);
    const [Tops, setTops] = useState(10);
    const [nodata, setNodat] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setElementData(ref.current);
        getinvitationList();
    }, []);
    /* #endregion */

    /* #region  CONSTANTS */
    const columns = [
        {
            name: "STORE ID",
            cell: row => row.storeID,
            selector: row => row.storeID,
            // selector: row => parseInt(Number(row.storeID)),
            sortable: true,
            width: "200px"
        },

        {
            name: "STORE NAME",
            cell: row => "-"
            // selector: row => row.tempGruop,
            //sortable: true
        },
        {
            name: "CITY",
            cell: row => "-",
            // selector: row => row.tempName,
            //sortable: true,
            width: "220px"
        },

        {
            name: "COUNTRY",
            cell: row => "-",
            // selector: row => row.tempEmail,
            //sortable: true,
            width: "250px"
        },
        {
            name: "BARCODE",
            //selector: row => row.GTINbarcode,
            selector: row => parseInt(Number(row.GTINbarcode)),
            cell: row => row.GTINbarcode,
            sortable: true,
            width: "250px"
        },
        {
            name: "BRAND",
            //selector: 'creationDate',
            //selector: row => row.creationDate?.split(" ")[0]?.split("-").reverse().join("-"),
            cell: row => "-"
            //sortable: true,
            //width: '220px'
        },

        {
            name: "STOCK",
            // selector: row => row.stock,
            //sortable: true,
            cell: row => row.stock
        },
        {
            name: "PRICE",
            selector: row => row.price,
            sortable: true,
            //	width: "200px",
            cell: row => parseFloat(row.price).toFixed(2)
        }
    ];

    const statusOptions = [{ value: "all", label: "All" }];

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            "backgroundColor": "#10141B",
            "borderColor": "#333F50",
            "border": state.isFocused ? "1px solid #0060CC" : "1px solid #333F50",
            "boxShadow": state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0060CC"
            },
            "&:active": {
                border: "1px solid #0060CC"
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                "backgroundColor": "#333F50",
                "color": "#d1d1d1",
                "cursor": "pointer",
                "border": "1px solid #10141B",
                "borderLeft": "1px solid transparent",
                "borderRight": "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF"
                },
                "&:active": {
                    backgroundColor: "#333F50"
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: "#10141B" }),
        menu: styles => ({ ...styles, backgroundColor: "#10141B", marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles,
            "padding": 0,
            "paddingRight": "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: "#d1d1d1" }),
        input: styles => ({ ...styles, color: "#d1d1d1" }),
        placeholder: styles => ({ ...styles, color: "#d1d1d1" }),
        noOptionsMessage: styles => ({ ...styles, backgroundColor: "#333F50", color: "#d1d1d1" }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#333F50",
            borderRadius: "0px"
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            "color": "#fff",
            "backgroundColor": "#333F50",
            "borderRadius": "0px",
            ":hover": {
                backgroundColor: "#333F50",
                color: "white"
            }
        })
    };
    /* #endregion */

    /* #region  METHODS */
    const getinvitationList = () => {
        let formData = {
            top: ""
        };
        Library.makePostRequest("get-stock-data", formData).then(res => {
            //axios.post(process.env.REACT_APP_API_URL + 'get-stock-data?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
            //axios.post(" http://localhost:7071/api/get-stock-data", formData).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data.data);
                let data = res.data.data;
                console.log(data.length);
                //  setTops(data.length);
                setInviteList(data);
                setShowLoader(false);
                setDataLoading(false);

                if (data.length) {
                    setNodat(0);
                } else {
                    setNodat(1);
                }
            } else {
                setNodat(1);
            }
        });
    };

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    const CustomLoader = () => (
        <div className="loaderclass">
            <i className="fas fa-spinner fa-spin"></i>
        </div>
    );
    /* #endregion */

    return (
        <>
            <div className="conten-wrapper">
                <div className="controlbar">
                    <div className="productsearch leftsidetr notactive">
                        <Link className="button-not-active" data-widget="search" to="#" role="button">
                            <span className="btn btn-success">
                                <i className="fa-thin fa-magnifying-glass"></i>
                            </span>
                            <span className="searchprodicttext">Brands</span>
                        </Link>
                    </div>

                    <div className="centerbtn">
                        <Link to="#" className="btn btn-success active">
                            STOCK & PRICES
                        </Link>
                    </div>

                    <div className="rightcontrolserch">
                        <div className="productsearch rightareas">
                            <Link data-widget="search" to="/stock" role="button">
                                <span className="btn btn-success ">
                                    <i className="fa-thin fa-database"></i>
                                </span>
                                <span className="searchprodicttext">Data</span>
                            </Link>
                        </div>
                        <div className="productsearch rightareas ">
                            <Link data-widget="search" to="stock/list" role="button">
                                <span className="btn btn-success active">
                                    <i className="fa-thin fa-list"></i>
                                </span>
                                <span className="searchprodicttext">List</span>
                            </Link>
                        </div>
                    </div>
                </div>

                {showLoader ? (
                    <PreLoader />
                ) : (
                    <>
                        <div className="filtersarea">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="serchbopicspr">
                                            <form className="form-inline">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Search list:"
                                                    aria-label="Search"
                                                    disabled="disabled"
                                                />
                                                <span className="customeset gobtn button-not-active">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success"
                                                        disabled="disabled">
                                                        <i className="fa-thin fa-arrow-right"></i>
                                                    </button>
                                                    <span className="searchprodicttext">Go</span>
                                                </span>
                                            </form>
                                        </div>
                                        <div className="filtersection invitesdiv">
                                            <form className="form-inline" name="filter">
                                                <p className="form-control-static">Filters:</p>

                                                <div className="form-group">
                                                    <label htmlFor="status" className="sr-only">
                                                        Status
                                                    </label>
                                                    <Select
                                                        options={statusOptions}
                                                        styles={colourStyles}
                                                        placeholder={<div class="italic-placeholder">Status</div>}
                                                        isDisabled="true"
                                                    />
                                                </div>
                                                <span className="btnsets">
                                                    <span className="customeset revertfilters button-not-active">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success "
                                                            disabled="disabled">
                                                            <i className="fa-thin fa-rotate-left"></i>
                                                        </button>
                                                        <span className="searchprodicttext">Reset</span>
                                                    </span>
                                                    <span className="customeset gobtn button-not-active">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success disabled"
                                                            disabled="disabled">
                                                            <i className="fa-thin fa-arrow-right"></i>
                                                        </button>
                                                        <span className="searchprodicttext">Apply</span>
                                                    </span>
                                                    <span className="customeset gobtn button-not-active">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success"
                                                            disabled="disabled">
                                                            <i className="fa-thin fa-arrows-rotate"></i>
                                                        </button>
                                                        <span className="searchprodicttext">Refresh</span>
                                                    </span>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="noDataFound" style={{ display: nodata ? "block" : "none" }}>
                                        No data Found
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{ "display": (inviteList.length) ? "block" : "none" }}> */}

                        <div style={{ display: "block" }}>
                            <div className="tablestructure ImsListshow">
                                <DataTable
                                    columns={columns}
                                    data={inviteList}
                                    theme="solarized"
                                    sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                    progressPending={dataLoading}
                                    selectableRows
                                    selectableRowsComponent={Checkbox}
                                    defaultSortFieldId={1}
                                    defaultSortAsc={true}
                                    responsive={true}
                                    expandableRows={false}
                                    progressComponent={<CustomLoader />}
                                    persistTableHead={true}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default ImsList;
