import React, { useRef } from "react";
import { Link } from "react-router-dom";

function Account(props) {
    document.title = "BOstageA - Account";

    const ref = useRef();

    return (
        <>
            <div className="conten-wrapper">
                <div className="controlbar">
                    <div className="productsearch leftsidetr notactive">
                        <Link className="button-not-active" data-widget="search" to="#" role="button">
                            <span className="btn btn-success">
                                <i className="fa-thin fa-magnifying-glass"></i>
                            </span>
                            <span className="searchprodicttext">Brands</span>
                        </Link>
                    </div>

                    <div className="centerbtn">
                        {
                            <Link to="#" className="btn btn-success">
                                Brands
                            </Link>
                        }
                        {
                            <Link to="#" className="btn btn-success active">
                                User List
                            </Link>
                        }
                        {
                            <Link to="#" className="btn btn-success ">
                                Importer
                            </Link>
                        }
                    </div>

                    <div className="rightcontrolserch">
                        <div className="productsearch rightareas">
                            {/* <Link data-widget="search" to="#" role="button">
                            <span className="btn btn-success "><i className="fa-thin fa-envelope-open-text"></i></span>
                            <span className="searchprodicttext">Invite</span>
                        </Link> */}
                        </div>
                        <div className="productsearch rightareas">
                            <Link data-widget="search" to="#" role="button">
                                <span className="btn btn-success active">
                                    <i className="fa-thin fa-list"></i>
                                </span>
                                <span className="searchprodicttext">List</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;
